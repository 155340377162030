import { DATE_FORMAT } from '@/utils/constants';
import { formatDate } from '@/utils/formatDate';
import { COMMON_STRING } from '@/constants/dictionary';
import { FixtureActionLatency } from '@/service/hooks/types/FixtureActionLatency';

export const generateBarItems = (latency?: FixtureActionLatency) => {
  return [
    {
      label: 'Description',
      value: latency?.collectionName || COMMON_STRING.N_A,
    },
    {
      label: 'Date',
      value: latency
        ? formatDate(latency.startDate, DATE_FORMAT.DATE)
        : COMMON_STRING.N_A,
    },
    {
      label: 'Time',
      value: latency
        ? formatDate(latency.startDate, DATE_FORMAT.HOURS_MINS)
        : COMMON_STRING.N_A,
    },
    {
      label: 'Sport',
      value: latency?.sportName || COMMON_STRING.N_A,
    },
    {
      label: 'Competition',
      value: latency?.competitionName || COMMON_STRING.N_A,
    },
    {
      label: 'Actions',
      value: latency?.fixtureActionsCount || COMMON_STRING.N_A,
    },
    {
      label: 'Average',
      value: latency?.averageTime
        ? latency.averageTime.toFixed(3)
        : COMMON_STRING.N_A,
    },
  ];
};
